export const propertyReferralsInfo = [
  {
    title: 'Social Media',
    percentage: 84,
    color: '#6C5DD3',
  },
  {
    title: 'Marketplace',
    percentage: 45,
    color: '#7FBA7A',
  },
  {
    title: 'Websites',
    percentage: 30,
    color: '#FFCE73',
  },
  {
    title: 'Digital Ads',
    percentage: 80,
    color: '#FFA2C0',
  },
  {
    title: 'Others',
    percentage: 15,
    color: '#F45252',
  },
];
